import clsx from 'clsx';
import Link from 'next/link';
import { FC, HTMLAttributes, memo, useMemo, useRef, useState } from 'react';

import { useVisibleItems } from '@/hooks/useVisibleItems';

import styles from './NavigationList.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  title: string;
  href?: string;
  items: { title: string; href: string }[];
  variant?: 'column' | 'default';
  mode?: 'accordion' | 'default';
};

interface NavigationItemProps {
  el: {
    title: string;
    href: string;
  };
}

const RightArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M3.16699 8H13.8337M13.8337 8L9.83366 4M13.8337 8L9.83366 12"
      stroke="#35B447"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6673 6L8.00065 10L3.33398 6"
      stroke="#969696"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NavigationList = ({
  variant = 'default',
  mode = 'default',
  title,
  href,
  items,
  className,
  ...rest
}: Props) => {
  const [isActive, setIsActive] = useState(false);
  const { visibleItems, containerRef } = useVisibleItems(items, 3);
  const HeaderMap = useMemo(
    () => ({
      accordion: (
        <button
          className={clsx(styles.accordionButton, isActive && styles.active)}
          onClick={() => setIsActive(!isActive)}
        >
          {title}
          {ChevronDown}
        </button>
      ),
      default: href ? (
        <Link className={styles.mainTitle} href={href} prefetch={false}>
          {title}
          {RightArrow}
        </Link>
      ) : (
        <span
          className={clsx(styles.mainTitle)}
          onClick={mode === 'accordion' ? () => setIsActive(!isActive) : undefined}
        >
          {title}
        </span>
      ),
    }),
    [isActive]
  );
  const NavigationItem: FC<NavigationItemProps> = ({ el }) => (
    <li>
      <Link href={el.href} prefetch={false}>
        {el.title}
      </Link>
    </li>
  );
  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      {HeaderMap[mode]}

      {(mode !== 'accordion' || (mode === 'accordion' && isActive)) && (
        <ul className={clsx(styles.navigation, styles[variant])} ref={containerRef}>
          {visibleItems.map((el: any) => (
            <NavigationItem key={el.title} el={el} />
          ))}
        </ul>
      )}
    </div>
  );
};
export default NavigationList;
